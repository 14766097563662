import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      whatwedo: file(relativePath: { eq: "hp-what-we-do.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1050) {
            src
          }
        }
      }
      operations: file(relativePath: { eq: "hp-operations.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1050) {
            src
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      <Helmet
        bodyAttributes={{
          class: "bg-power-lines",
        }}
      />
      <div className="container mx-auto flex px-4 md:px-0 md:justify-end py-12 md:pb-48 md:pt-32">
        <div className="md:w-1/2">
          <h1 className="uppercase text-4xl font-black tracking-wider">
            We are a progressive, client-oriented engineering consulting firm
          </h1>
          <p className="text-2xl py-6">
            Our goal is to provide the knowledge, skills, experience, and
            facilities required to meet our clients’ needs in defining and
            fulfilling their objectives. Our standards of conduct are integrity,
            professional competence, and economy in engineering services
          </p>
          <div className="md:flex py-8">
            <Link
              to="/contact"
              className="text-center md:text-left bg-blue-800 hover:bg-blue-600 uppercase tracking-widest rounded-md py-4 px-8 block md:mr-12 mb-8 md:mb-0"
            >
              Contact Us
            </Link>
            <Link
              to="/services"
              className="text-center md:text-left bg-green-800 hover:bg-green-600 uppercase tracking-widest rounded-md py-4 px-8 block"
            >
              Our Work
            </Link>
          </div>
        </div>
      </div>
      <div className="md:pt-48 mt-24 flex flex-wrap">
        <div className="w-full md:w-1/2">
          <img src={data.whatwedo.childImageSharp.fluid.src} alt="Powerlines" />
        </div>
        <div className="w-full md:w-1/2 md:px-48 px-4 md:px-0 text-xl">
          <h2 className="text-3xl font-bold tracking-widest uppercase mt-4 mb-4 md:mt-0 md:mb-8">
            What We Do
          </h2>
          <p className="mb-8">
            Southeastern offers a complete range of electrical engineering and
            consulting services to our clients. Whether you need a design for a
            small downtown street lighting project or are interconnecting a
            large combustion turbine or renewable generator to the electrical
            grid, we can be of service.
          </p>
          <p>
            We provide services to publicly owned, industrial, and institutional
            electric systems. We also offer electrical engineering services to
            water and wastewater treatment plants. Our intent is to provide the
            facilities, skills and experience required to meet the client’s
            needs. Our standards are integrity, professional competence and
            economy in engineering services
          </p>
        </div>
      </div>
      <div className="pb-24 md:pb-48 mt-12 md:mt-0 flex flex-wrap">
        <div className="w-full md:w-1/2 md:pt-24 px-4 md:px-48 text-xl">
          <h2 className="text-3xl font-bold tracking-widest uppercase mb-8 mt-4 mb-4 md:mt-0 md:mb-8">
            Operations
          </h2>
          <p className="mb-8">
            Wherever practical, all projects commissioned by a single client are
            managed by the same engineer, in order to minimize the time and
            expense of job familiarization. We feel this method of project
            assignment also provides more convenient and efficient
            communications as the client generally can call one person who can
            readily answer even detailed questions concerning current or past
            projects.
          </p>
          <p>
            However, this does not preclude our design engineers from sharing
            new ideas or discussing possible solutions to project requirements
            in order to take full advantage of our collective knowledge and
            experience. This open exchange of ideas also leads to
            standardization of methods and procedures which allows all of the
            firm’s engineers to be familiar with the basics of each project,
            thereby providing continuity and efficiency. All of our design
            engineers are employee owners’ of the company.
          </p>
        </div>
        <div className="w-full md:w-1/2 order-first md:order-last">
          <img
            src={data.operations.childImageSharp.fluid.src}
            alt="Operations"
          />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
